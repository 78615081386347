.checkboxRound span[class*='checkbox__control'] {
  border-color: #6c6c6c !important;
  padding: 5px !important;
  border-radius: 50% !important;
}

.checkboxRound span[class*='checkbox__control'][data-checked] {
  border-color: white !important;
}

.checkboxRound span[class*='chakra-checkbox__label'] {
  font-size: 12px !important;
}

.customCss
  div[class*='control']
  div:nth-child(2)
  div[class*='indicatorContainer'] {
  padding: 0px 4px !important;
}
