.radioFill span[class*='chakra-radio__label'] {
  font-size: 12px !important;
}

.customCssActivated
  div[class*='control']
  div:nth-child(2)
  div[class*='indicatorContainer'] {
  padding: 0px 4px 0px 0px !important;
}

.customCssActivated
  div[class*='control']
  div:nth-child(2)
  div[class*='indicatorContainer']
  svg {
  height: 16px;
  width: 16px;
}
.customCssActivated div[class*='control'] div[class*='singleValue'] div {
  gap: 6px;
}
.customCssDeactivated
  div[class*='control']
  div:nth-child(2)
  div[class*='indicatorContainer'] {
  padding: 0px 4px 0px 0px !important;
  color: #667085;
}

.customCssDeactivated
  div[class*='control']
  div:nth-child(2)
  div[class*='indicatorContainer']
  svg {
  height: 16px;
  width: 16px;
}
.customCssDeactivated div[class*='control'] div[class*='singleValue'] div {
  gap: 6px;
}
